import React from 'react'
import { makeStyles, Grid, Hidden, Typography } from '@material-ui/core'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Title from '../components/Title'
import Section from '../components/Section'
import Highlight from '../components/Highlight'
import WorldMap from '../components/WorldMap'

const useStyles = makeStyles(theme => ({
  quotes: {
    borderLeftStyle: 'solid',
    borderLeftColor: theme.palette.primary.main,
    borderLeftWidth: 3,
    padding: theme.spacing(1),
    fontStyle: 'italic',
    backgroundColor: '#F9F9FB',
    borderRadius: 8,
  },
  image: {
    width: '70%',
    height: '70%',
    margin: '0',
    borderRadius: 13,
  },
  row: {
    marginTop: theme.spacing(2),
  },
}))

const AboutUsPage = () => {
  const classes = useStyles()

  return (
    <Layout>
      <SEO title='About Us' />

      <Section>
        <blockquote className={classes.quotes}>
          As a well recognized company among Semiconductor industry. Almag is committed to provide{' '}
          <Highlight>high quality, fast delivery</Highlight> and <Highlight>good technical assistance</Highlight> to our
          customer.
        </blockquote>
        <blockquote className={classes.quotes}>
          We are pleased to work closely with customer in{' '}
          <Highlight>exchanging ideas, design and develop new products</Highlight> in meeting client requirements
        </blockquote>
      </Section>

      <Section>
        <Grid container className={classes.row} alignItems='center' spacing={2}>
          <Hidden smUp>
            <Grid item sm={6} xs={12}>
              <Title label='Design' description='Developing new products and product enhancement' />
            </Grid>
          </Hidden>
          <Grid item sm={6} xs={12} style={{ textAlign: 'center' }}>
            <img src={require('../images/services/design.jpeg')} alt='almag design' className={classes.image} />
          </Grid>
          <Hidden xsDown>
            <Grid item sm={6} xs={12}>
              <Title label='Design' description='Developing new products and product enhancement' />
            </Grid>
          </Hidden>
        </Grid>
        <Grid container className={classes.row} alignItems='center' spacing={2}>
          <Grid item sm={6} xs={12}>
            <Title label='Quality' description='Product Quality is always our priority' />
          </Grid>
          <Grid item sm={6} xs={12} style={{ textAlign: 'center' }}>
            <img src={require('../images/services/quality.png')} alt='almag quality' className={classes.image} />
          </Grid>
        </Grid>
        <Grid container className={classes.row} alignItems='center' spacing={2}>
          <Hidden smUp>
            <Grid item sm={6} xs={12}>
              <Title label='Delivery' description='On time delivery to ensure customer satisfaction' />
            </Grid>
          </Hidden>
          <Grid item sm={6} xs={12} style={{ textAlign: 'center' }}>
            <img src={require('../images/services/delivery.jpeg')} alt='almag delivery' className={classes.image} />
          </Grid>
          <Hidden xsDown>
            <Grid item sm={6} xs={12}>
              <Title label='Delivery' description='On time delivery to ensure customer satisfaction' />
            </Grid>
          </Hidden>
        </Grid>
        <Grid container className={classes.row} alignItems='center' spacing={2}>
          <Grid item sm={6} xs={12}>
            <Title label='Service' description='Customer support thru online as well as physical discussion' />
          </Grid>
          <Grid item sm={6} xs={12} style={{ textAlign: 'center' }}>
            <img src={require('../images/services/service.jpeg')} alt='almag service' className={classes.image} />
          </Grid>
        </Grid>
      </Section>

      <Section>
        <p>
          Almag Sdn Bhd, started in 1986 is an established manufacturer providing one-stop metal fabrication solution.
          We are the leading designer and manufacturer from Front-of-Line to End-of-Line processing products in
          semiconductor industry
        </p>
        <p>
          Almag Sdn Bhd, leads the industry by establishing a vertically integrated production system; whereas the
          system starts from customer involvement of product design, sample fabrication, mould development,
          manufacturing, and processing until finished products.
        </p>
        <p>
          Besides being a main supplier of Semiconductor Magazine in Malaysia, we have businesses supplying
          Semiconductor Magazine all around the world
        </p>
      </Section>

      <Section>{typeof window !== 'undefined' && <WorldMap />}</Section>

      <Section>
        <iframe
          title='map'
          width='100%'
          height='400'
          frameBorder='0'
          style={{ border: 'none', marginBottom: 0 }}
          allowFullScreen
          src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1992.2364508649055!2d101.8277887!3d2.9662!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cdcc3b99896627%3A0xfa23e2458b8deb95!2sAlmag%20Sdn%20Bhd!5e0!3m2!1sen!2smy!4v1705457494701!5m2!1sen!2smy'
        ></iframe>
      </Section>

      <Section>
        <Grid container alignItems='center'>
          <Grid item sm={6} xs={12}>
            <Typography variant='h6'>Almag Sdn. Bhd.</Typography>
            <Typography>
              <a
                style={{ color: 'black' }}
                target='_blank'
                rel='noreferrer'
                href='https://www.google.com/maps/dir//Almag+Sdn+Bhd+No.+1,+Section+2,+43500,+Semenyih,+Malaysia+Jalan+P2%2F18+Bandar+Teknologi+Kajang,+43500+Semenyih,+Selangor/@2.9662,101.8277887,18z/data=!4m8!4m7!1m0!1m5!1m1!1s0x31cdcc3b99896627:0xfa23e2458b8deb95!2m2!1d101.8277887!2d2.9662?hl=en&entry=ttu'
              >
                No. 1, Jalan P2/18 Section 2,
                <br />
                Bandar Technology Kajang,
                <br />
                43500 Semenyih, Selangor D.E.,
                <br />
                Malaysia.
              </a>
            </Typography>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Typography>
              <strong>Tel: </strong>+ 603 - 8724 1111 (Hunting Line)
            </Typography>
            <Typography>
              <strong>Whatsapp: </strong>
              <a href='https://wa.me/60122466356?text=Hello%20Almag%20teams,%0A%0A' style={{ color: 'black' }}>
                + 6012 - 246 6356
              </a>
            </Typography>
            <Typography>
              <strong>Email: </strong>
              <a href='mailto:enquiry@almagsb.com' style={{ color: 'black' }}>
                enquiry@almagsb.com
              </a>
            </Typography>
          </Grid>
        </Grid>
      </Section>
    </Layout>
  )
}

export default AboutUsPage
