/**
 *
 * @description Title.js
 * @author yikkok <yikok.yong@gmail.com>
 * @version 1.0.0
 * @since 18 July 2020
 *
 */

import * as React from 'react'
import { Typography, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  label: {
    fontSize: '2rem',
    fontFamily: '"Roboto", "Arial", "Helvetica", sans-serif',
  },
  typography: theme.typography,
  description: {
    fontSize: '20px',
    fontFamily: '"Roboto", "Arial", "Helvetica", sans-serif',
  },
}))

export default function Title(props) {
  const { label, description } = props
  const classes = useStyles()

  return (
    <>
      <Typography align='center' className={classes.typography}>
        {label}
      </Typography>
      <Typography align='center' className={classes.description}>
        {description}
      </Typography>
    </>
  )
}
