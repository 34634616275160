/**
 *
 * @description WorldMap.js
 * @author yikkok <yikok.yong@gmail.com>
 * @version 1.0.0
 * @since 18 July 2020
 *
 */

import * as React from 'react'
import { ComposableMap, Geographies, Geography, Marker } from 'react-simple-maps'
import { Grid } from '@material-ui/core'
import myTheme from '../utils/myTheme'
import Map from './map.json'

const markers = [
  {
    markerOffset: -5,
    name: 'Malaysia',
    coordinates: [101.975766, 4.210484],
  },
  {
    markerOffset: 15,
    name: 'Singapore',
    coordinates: [103.819836, 1.352083],
  },
  {
    markerOffset: -5,
    name: 'Thailand',
    coordinates: [100.992541, 15.870032],
  },
  {
    markerOffset: -10,
    name: 'Philippines',
    coordinates: [121.774017, 12.879721],
  },
  {
    markerOffset: 20,
    name: 'Indonesia',
    coordinates: [113.921327, -0.789275],
  },
  {
    markerOffset: 15,
    name: 'Japan',
    coordinates: [138.252924, 36.204824],
  },
  {
    markerOffset: 15,
    name: 'China',
    coordinates: [104.195397, 35.86166],
  },
  {
    markerOffset: 15,
    name: 'Vietnam',
    coordinates: [108.277199, 14.058324],
  },
  {
    markerOffset: -5,
    name: 'Germany',
    coordinates: [10.451526, 51.165691],
  },
  {
    markerOffset: 15,
    name: 'Italy',
    coordinates: [12.56738, 41.87194],
  },
  {
    markerOffset: -5,
    name: 'France',
    coordinates: [2.213749, 46.227638],
  },
  {
    markerOffset: 15,
    name: 'USA',
    coordinates: [-95.712891, 37.09024],
  },
  {
    markerOffset: 15,
    name: 'Mexico',
    coordinates: [-102.552788, 23.634501],
  },
  {
    markerOffset: 15,
    name: 'Morocco',
    coordinates: [-7.09262, 31.791702],
  },
  {
    markerOffset: 15,
    name: 'Austria',
    coordinates: [14.550072, 47.516232],
  },
]
const matchSpecificArea = (val, criterias) => {
  const regex = new RegExp(criterias, 'gi')

  return regex.test(val)
}

export default function WorldMap() {
  return (
    <Grid container>
      <Grid item xs={12}>
        <ComposableMap
          projectionConfig={{
            scale: 200,
          }}
        >
          <Geographies geography={Map}>
            {({ geographies }) => {
              return geographies.map(geo => {
                return (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    fill={
                      matchSpecificArea(geo.properties.SUBREGION, 'South-Eastern Asia') ||
                      matchSpecificArea(geo.properties.CONTINENT, 'America') ||
                      matchSpecificArea(geo.properties.CONTINENT, 'Asia') ||
                      matchSpecificArea(geo.properties.CONTINENT, 'Europe')
                        ? '#99D8C9'
                        : '#eee'
                    }
                  />
                )
              })
            }}
          </Geographies>
          {markers.map(({ name, coordinates, markerOffset }) => (
            <Marker key={name} coordinates={coordinates}>
              <g
                fill={myTheme.palette.primary.main}
                strokeLinecap='round'
                strokeLinejoin='round'
                transform='translate(-12, -24)'
              >
                <circle cx='15' cy='25' r='4' />
              </g>
              <text
                textAnchor='middle'
                y={markerOffset}
                style={{
                  fontSize: 12,
                  fontFamily: 'system-ui',
                  fill: 'black',
                }}
              >
                {name}
              </text>
            </Marker>
          ))}
        </ComposableMap>
      </Grid>
    </Grid>
  )
}
